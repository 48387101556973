<template>
  <div class="container-fluid">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPA_TRANSACTIONS)"
          :to="{ name: 'ListDpaTransactions' }"
          class="col-6 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="esign-packages shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
            <i class="fa fa-folder" aria-hidden="true"></i>
            </span>
            <small>{{ $t("DPA_PPA.TRANSACTIONS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPA_FILES)"
          :to="{ name: 'ListDpaFiles' }"
          class="col-6 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="esign-packages shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
            <i class="fa fa-folder" aria-hidden="true"></i>
            </span>
            <small>{{ $t("DPA_PPA.FILES") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPA_ACCOUNTS)"
          :to="{ name: 'ListDpaAccounts' }"
          class="col-6 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="esign-packages shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
            <i class="fa fa-lock" aria-hidden="true"></i>
            </span>
            <small>{{ $t("DPA_PPA.ACCOUNTS") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",
};
</script>
